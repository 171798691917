import { httpfb, httpwmsapi } from "src/services";
import queryString from "query-string";
import axios from "axios";

// Action Types
export const Types = {
  PACKING_LOADING: "packing/loading",
  PACKING_BOX_SET_DATA: "packing/box/set/data",
  PACKING_SET_DATA: "packing/set/data",
  PACKING_SET_TYPE: "packing/set/type", //-
  PACKING_SET_BOXES: "packing/set/boxes", //-
  PACKING_SET_ORDER: "packing/set/order", //-
  PACKING_SET_ORDERS: "packing/set/orders", //-
  PACKING_SET_ITEMS: "packing/set/items",
  PACKING_SET_PACKED_ORDER: "packing/set/packed/order",
  PACKING_SET_PAGING: "packing/set/paging",
  PACKING_SET_LISTED: "packing/set/listed",
  PACKING_SET_PACKAGE: "packing/set/package"
};

// Reducer
const initialState = {
  loading: false,
  packing_type: "", //plDiscreto|plDiscretoUnitario|plDiscretoCarrinho
  packing_boxes: [], //Array de boxes
  packing_order: {}, // plDiscreto
  packing_orders: [], // plDiscretoUnitario
  packing_items: [],
  packing_packages: [],
  packing_data: {
    packing_boxes: [], // ["2BF71", "2A5A1"], Cx Selecionads
    packing_items: [], // items conferidos.
    packing_order: {}, // detalhes do pedidos
    packing_packages: [] // embalagem
  },
  packed_order: {},
  packing_listed: [],
  packing_paging: { limit: 25, offset: 0, total: 0 },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.PACKING_LOADING:
      return { ...state, loading: action.payload };
    case Types.PACKING_BOX_SET_DATA:
      return { ...state, packing_boxes: action.payload };
    case Types.PACKING_SET_TYPE:
      return { ...state, packing_type: action.payload };
    case Types.PACKING_SET_ORDER:
      return { ...state, packing_order: action.payload };
    case Types.PACKING_SET_ORDERS:
      return { ...state, packing_orders: action.payload };
    case Types.PACKING_SET_DATA:
      return { ...state, packing_data: action.payload };
    case Types.PACKING_SET_ITEMS:
      return { ...state, packing_items: action.payload };
    case Types.PACKING_SET_PACKED_ORDER:
      return { ...state, packed_order: action.payload };
    case Types.PACKING_SET_PAGING:
      return { ...state, packing_paging: action.payload };
    case Types.PACKING_SET_LISTED:
      return { ...state, packing_listed: action.payload };
    case Types.PACKING_SET_PACKAGE:
      return { ...state, packing_package: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  packingLoading: (value) => ({ type: Types.PACKING_LOADING, payload: value }),
  packingListed: (value) => ({
    type: Types.PACKING_SET_LISTED,
    payload: value,
  }),
  packingPaging: (value) => ({
    type: Types.PACKING_SET_PAGING,
    payload: value,
  }),
  packingBoxSetData: (value) => ({
    type: Types.PACKING_BOX_SET_DATA,
    payload: value,
  }),
  packingSetType: (value) => ({ type: Types.PACKING_SET_TYPE, payload: value }),
  packingSetOrder: (value) => ({
    type: Types.PACKING_SET_ORDER,
    payload: value,
  }),
  packingSetOrders: (value) => ({
    type: Types.PACKING_SET_ORDERS,
    payload: value,
  }),
  packingSetData: (value) => ({ type: Types.PACKING_SET_DATA, payload: value }),
  packingSetItems: (value) => ({
    type: Types.PACKING_SET_ITEMS,
    payload: value,
  }),
  packingSetPackedOrder: (value) => ({
    type: Types.PACKING_SET_PACKED_ORDER,
    payload: value,
  }),
  packingSetPackage: (value) => ({
    type: Types.PACKING_SET_PACKAGE,
    payload: value,
  }),
  setPackingBox: (box_key) => {
    return new Promise((resolve, reject) => {
      httpfb
        .patch(`/logistics/packing-list/pack-order-by-box/${box_key}`, {
          timeout: 1000 * 25,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setPackingOrder: (order_id, body) => {
    return new Promise((resolve, reject) => {
      httpfb
        .put(`/orders/${order_id}/packing`, body, { timeout: 1000 * 30 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getNfeOrderId: (order_id) => {
    if (!order_id) return Promise.reject("Pedido não informado.");
    
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/orders/${order_id}/invoice/zpl-data`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getLabelOrderId: (order_id) => {
    if (!order_id) return Promise.reject("Pedido não informado.");

    return new Promise((resolve, reject) => {
      httpfb
        .get(`/orders/${order_id}/label/zpl-data`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getLabelForNfeKey: (invoice_key) => {
    if (!invoice_key) return Promise.reject("Chave da NFe não informada.");

    return new Promise((resolve, reject) => {
      httpfb
        .get(`/invoices/${invoice_key}/order-zpl-data`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getVerifyNfe: (order_id, nfe_barcode) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/orders/${order_id}/invoice/key/${nfe_barcode}`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setLabelConfirm: (order_id) => {
    return new Promise((resolve, reject) => {
      httpfb
        .patch(`/orders/${order_id}/label/printed`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getVerifyLabel: (order_id, label_barcode) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/orders/${order_id}/label/barcode/${label_barcode}`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPackages: (filters={}) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index"}).replaceAll('%5B', '[').replaceAll('%5D', ']')
    return new Promise( (resolve, reject) => {
        httpwmsapi
          .get(`/v1/embalagens/?${qry}`, {timeout: 1000 * 50})
          .then(({ data }) => resolve(data)).catch( error => reject(error))
    })
  },
  setPackageInventory: (body) => {
    return new Promise((resolve, reject) => {
      httpwmsapi
        .post(`/v1/estoque-embalagem/`, body, { headers: {'Content-Type': 'application/json'}, timeout: 1000 * 60 })
        .then(( data ) => resolve(data))
        .catch((error) => reject(error));
    });
},
  getOrdersConferred: (filters = {}) => {
    /**
     * current_user_only=true
     * limit=50
     * offset=0
     */
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfb
        .get(`logistics/packing-list/packed?${qry}`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  printLabel: (label, label_zpl) => {
    const url = "127.0.0.1"; //'127.0.0.1:9009' | '10.0.0.125:3109'
    return new Promise((resolve, reject) => {
      axios
        .post(
          `http://${url}:9009/print`,
          { label, label_zpl },
          {
            auth: {
              username: "weesu",
              password: "weesu",
            },
          }
        )
        .then(() => resolve())
        .catch(() => reject());
    });
  },
  validateNfeByCodigo: (order_id) => {
    return new Promise((resolve, reject) => {
      httpwmsapi
        .get(`/v1/pedido/${order_id}/validar`, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
