import httpwmsapi from '../httpwmsapi';
import httpfb from '../httpfb';
import httpfbnd from '../httpfbnd';
import http from "./index";

const getToken = () => localStorage.getItem("accessToken");

const validateToken = (token) => {
  if (token) {
    //:TODO rever jsonwebtoken
    // let dateNow = new Date();
    // const decodedToken = jwt.decode(token, { complete: true });
    // return decodedToken.payload.exp < dateNow.getTime();
    return true
  }

  return false;
}

const handleInvalidToken = () => {
  window.location.href = "/login";
}

const createRequestInterceptor = (httpInstance) => {
  return httpInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
      if (validateToken(token)) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        handleInvalidToken();
      }
    }
    return config;
  });
}

const requestInterceptor = (store) => {
  const instances = [httpwmsapi, httpfbnd, httpfb, http];
  instances.forEach(instance => createRequestInterceptor(instance));
};

export { requestInterceptor };