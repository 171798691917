import { httpwmsapi } from "src/services";
import queryString from "query-string";

export const Types = {
    PACKAGE_LOADING:'package/loading',

    PACKAGE_SET_DATA:'package/set/data',
    PACKAGES_SET_DATA:'packages/set/data',
    PACKAGES_SET_PAGING:'packages/set/paging',
    PACKAGES_SET_FILTER:'packages/set/filter',

    PACKAGE_INVENTORY_SET_DATA:'package/set/inventory/data',
    PACKAGES_INVENTORY_SET_DATA:'packages/set/inventory/data',
    PACKAGES_INVENTORY_SET_PAGING:'packages/set/inventory/paging',
    PACKAGES_INVENTORY_SET_FILTER:'packages/set/inventory/filter',
};

const initialState = {
    loading: false,

    package: {},
    packages_list: [],
    packages_paging: { limit: 25, offset: 0, total: 0, page: 0},
    packages_filters: [],

    package_inventory: {},
    packages_inventory_list: [],
    packages_inventory_paging: { limit: 25, offset: 0, total: 0, page: 0},
    packages_inventory_filters: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PACKAGE_LOADING:
            return { ...state, loading: action.payload };
        case Types.PACKAGE_SET_DATA:
            return { ...state, package: action.payload };
        case Types.PACKAGES_SET_DATA:
            return { ...state, packages_list: action.payload };
        case Types.PACKAGES_SET_PAGING:
            return { ...state, packages_paging: action.payload };
        case Types.PACKAGES_SET_FILTER:
            return { ...state, packages_filters: action.payload };
        case Types.PACKAGE_INVENTORY_SET_DATA:
            return { ...state, package_inventory: action.payload };
        case Types.PACKAGES_INVENTORY_SET_DATA:
            return { ...state, packages_inventory_list: action.payload };
        case Types.PACKAGES_INVENTORY_SET_PAGING:
            return { ...state, packages_inventory_paging: action.payload };
        case Types.PACKAGES_inventory_SET_FILTER:
            return { ...state, packages_inventory_filters: action.payload };
    default:
        return state;
    }
}

export const Creators = {
    packageLoading: value => ({ type: Types.PACKAGE_LOADING, payload: value }),
    packageSetData: packageObject => ({ type: Types.PACKAGE_SET_DATA, payload: packageObject }),
    packagesSetData: packages_list => ({ type: Types.PACKAGES_SET_DATA, payload: packages_list }),
    packagesSetPaging: packages_paging => ({ type: Types.PACKAGES_SET_PAGING, payload: packages_paging }),
    packagesSetFilters: packages_filters => ({ type: Types.PACKAGES_SET_FILTER, payload: packages_filters }),
    packageInventorySetData: package_inventory => ({ type: Types.PACKAGE_INVENTORY_SET_DATA, payload: package_inventory }),
    packagesInventorySetData: packages_inventory => ({ type: Types.PACKAGES_INVENTORY_SET_DATA, payload: packages_inventory }),
    packagesInventorySetPaging: packages_inventory_paging => ({ type: Types.PACKAGES_INVENTORY_SET_PAGING, payload: packages_inventory_paging }),
    packagesInventorySetFilters: packages_inventory_filters => ({ type: Types.PACKAGES_INVENTORY_SET_FILTER, payload: packages_inventory_filters }),

    getPackages: (filters={}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index"}).replaceAll('%5B', '[').replaceAll('%5D', ']')
        return new Promise( (resolve, reject) => {
            httpwmsapi.get(`/v1/embalagens/?${qry}`, {timeout: 1000 * 50})
                .then(({ data }) => resolve(data)).catch( error => reject(error))
        })
    },

    setPackage: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
            .post(`/v1/embalagens/`, body, { headers: {'Content-Type': 'application/json'}, timeout: 1000 * 60 })
            .then(( data ) => resolve(data))
            .catch((error) => reject(error));
        });
    },

    putPackage: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
            .put(`/v1/embalagens/`, body, { headers: {'Content-Type': 'application/json'}, timeout: 1000 * 60 })
            .then(( data ) => resolve(data))
            .catch((error) => reject(error));
        });
    },

    setPackageInventory: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
            .post(`/v1/estoque-embalagem/`, body, { headers: {'Content-Type': 'application/json'}, timeout: 1000 * 60 })
            .then(( data ) => resolve(data))
            .catch((error) => reject(error));
        });
    },

    getPackagesInventory: (filters={}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index"}).replaceAll('%5B', '[').replaceAll('%5D', ']')
        return new Promise( (resolve, reject) => {
            httpwmsapi.get(`/v1/estoque-embalagem/?${qry}`, {timeout: 1000 * 50})
                .then(({ data }) => resolve(data)).catch( error => reject(error))
        })
    },

    getPackageByCodigo: (codigo) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
            .get(`/v1/embalagens/${codigo}`, {
                timeout: 1000 * 60,
            })
            .then(({ data }) => resolve(data))
            .catch((error) => reject(error));
        });
    },

    deletePackageByCodigo: (codigo) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
            .delete(`/v1/embalagens/${codigo}`, {
                timeout: 1000 * 15,
            })
            .then(({ data }) => resolve(data))
            .catch((error) => reject(error));
        });
    }
}