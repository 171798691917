import { httpfb } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
  PICKING_LOADING: "picking/loading",
  PICKING_SALES_SET_DATA: "picking/sales/set/data",
  PICKING_SET_DATA: "picking/set/data",
  PICKING_SALES_SET_PAGING: "picking/sales/set/paging",
  PICKING_ABORT_REASONS: "picking/abort/reasons",
  PICKING_TYPE: "picking/type",
};

// Reducer
const initialState = {
  loading: false,
  picking_sales: [],
  picking_sales_paging: { limit: 25, offset: 0, total: 0 },
  picking_data: {
    order_id: 0,
    status_id: 0,
    status_name: "",
    order_id_marketplace: "",
    created_at: "",
    approved_at: "",
    integrated_at: "",
    buyer: {
      id: null,
      name: "",
    },
    marketplace: {
      id: 0,
      name: "",
      platform_id: 0,
      platform_name: "",
    },
    shipping: {
      method_id: 0,
      method_name: "",
      estimated_handling_limit: "",
    },
    items: [],
    picking_info: {
      current_user: "",
      started_at: "",
      picking_boxes: [],
    },
  },
  picking_type: "",
  abort_reasons: [],
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.PICKING_TYPE:
      return { ...state, picking_type: action.payload };
    case Types.PICKING_LOADING:
      return { ...state, loading: action.payload };
    case Types.PICKING_SALES_SET_DATA:
      return { ...state, picking_sales: action.payload };
    case Types.PICKING_SALES_SET_PAGING:
      return { ...state, picking_sales_paging: action.payload };
    case Types.PICKING_SET_DATA:
      return { ...state, picking_data: action.payload };
    case Types.PICKING_ABORT_REASONS:
      return { ...state, abort_reasons: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  pickingType: (value) => ({ type: Types.PICKING_TYPE, payload: value }),
  pickingLoading: (value) => ({ type: Types.PICKING_LOADING, payload: value }),
  pickingSetData: (value) => ({ type: Types.PICKING_SET_DATA, payload: value }),
  pickingSalesSetData: (value) => ({
    type: Types.PICKING_SALES_SET_DATA,
    payload: value,
  }),
  pickingSalesSetPaging: (paging) => ({
    type: Types.PICKING_SALES_SET_PAGING,
    payload: paging,
  }),
  pickingSetAbortReasons: (reason) => ({
    type: Types.PICKING_ABORT_REASONS,
    payload: reason,
  }),
  getPickingSales: (filters = {}) => {
    const qry = queryString.stringify(filters, { arrayFormat: "index" });
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/logistics/picking-list?${qry}`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setPickingOrder: (force_discreto_profile = false) => {
    return new Promise((resolve, reject) => {
      const body = force_discreto_profile ? { "force_picking_profile": "plDiscreto" } : {}
      httpfb
        .patch(
          `/logistics/picking-list/pickup-order`,
          body,
          { timeout: 1000 * 60 }
        )
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setPickingConclued: (order_id, body) => {
    return new Promise((resolve, reject) => {
      httpfb
        .post(`/orders/${order_id}/picking`, body, { timeout: 1000 * 60 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setPickingBoxOrder: (order_id, body) => {
    return new Promise((resolve, reject) => {
      httpfb
        .put(`/orders/${order_id}/picking-boxes`, body, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPickingBoxOrder: (order_id) => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/orders/${order_id}/picking-boxes`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  delPickingBoxOrder: (order_id, picking_box_code) => {
    return new Promise((resolve, reject) => {
      httpfb
        .delete(`/orders/${order_id}/picking-boxes/key/${picking_box_code}`, {
          timeout: 1000 * 15,
        })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  getPickingAbortReasons: () => {
    return new Promise((resolve, reject) => {
      httpfb
        .get(`/logistics/picking-list/abort-reasons`, { timeout: 1000 * 15 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  setPickingAbort: (order_id, body) => {
    return new Promise((resolve, reject) => {
      httpfb
        .post(`/orders/${order_id}/picking/abort`, body, { timeout: 1000 * 20 })
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};
