import { Chip, Typography } from "@mui/material";
import { Package } from "react-feather";
import { emptyToNull, toCamelCase } from "src/utils/getUtils";

function StockAddressView({
  data = {},
  direct = false,
  apartmentColor = "#31b7bc",
  variant = "h6",
  marginTop = 0,
  style = {},
  hideQuantity = true,
  quantity,
  marginLeft = "auto",
}) {

  const value = toCamelCase(data);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        marginTop,
        ...style,
      }}
    >
      {direct === false ? (
        <Typography
          align="left"
          variant={variant}
          style={{
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {`${emptyToNull(value.stockId) !== "" ? `AR${value.stockId}` : ``}`}
          {`${emptyToNull(value.block) !== "" ? ` - BL${value.block}` : ""}`}
          {`${emptyToNull(value.street) !== "" ? ` - RUA${value.street}` : ""}`}
          {`${emptyToNull(value.floor) !== "" ? ` - AD${value.floor}` : ""}`}
          {`${emptyToNull(value.tower) !== "" ? ` - P${value.tower}` : ""}`}
        </Typography>
      ) : (
        <Typography
          align="left"
          variant={variant}
          style={{
            marginLeft,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {value?.split("-").slice(0, -1).join(" - ")}
        </Typography>
      )}
      {emptyToNull(value?.seBox) !== "" ? (
        <>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: "#31b7bc",
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {`CX${value?.seBox}`}
          </Typography>
        </>
      ) : (
        ""
      )}
      {emptyToNull(value?.sePallet) !== "" ? (
        <>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            color={"primary"}
            variant="h5"
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: "#31b7bc",
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {`PL${value?.sePallet}`}
          </Typography>
        </>
      ) : (
        ""
      )}
      {emptyToNull(value?.apartment) !== "" || direct ? (
        <>
          <Typography
            align="left"
            variant={variant}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            &nbsp;-&nbsp;
          </Typography>
          <Typography
            align="left"
            variant={variant}
            style={{
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              backgroundColor: apartmentColor,
              color: "#fff",
              borderRadius: 5,
              padding: 5,
            }}
          >
            {direct === false ? `A${value.apartment}` : value.split("-").pop()}
          </Typography>
        </>
      ) : (
        ""
      )}
      {!hideQuantity && parseInt(value.physicalQuantity) > 0 ? (
        <Chip
          style={{ margin: 10, fontSize: 20, marginLeft: "auto" }}
          icon={<Package />}
          variant="outlined"
          color={parseInt(value.physicalQuantity) > 0 ? "primary" : "secondary"}
          label={value.physicalQuantity}
        />
      ) : !hideQuantity && quantity ? (
        <Chip
          style={{
            margin: 0,
            fontSize: 15,
            marginLeft: 5,
            color: parseInt(quantity) > 0 ? "#5c5c5c" : "secondary",
            borderColor: parseInt(quantity) > 0 ? "#5c5c5c" : "secondary",
          }}
          icon={<Package size={20} />}
          variant="outlined"
          color={parseInt(quantity) > 0 ? "primary" : "secondary"}
          label={quantity}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default StockAddressView;
