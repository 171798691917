import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, Typography } from '@mui/material';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const totalPages = Math.ceil(count / rowsPerPage);

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, totalPages - 1);
    };

    const handlePageButtonClick = (event, newPage) => {
        onPageChange(event, newPage);
    };

    const renderPageButtons = () => {
        const buttons = [];
        const maxButtons = 3;
        let startPage = Math.max(0, page - Math.floor(maxButtons / 2));
        let endPage = startPage + maxButtons - 1;

        if (endPage >= totalPages) {
            endPage = totalPages - 1;
            startPage = Math.max(0, endPage - maxButtons + 1);
        }

        if (startPage > 0) {
            buttons.push(
                <Button
                    key={0}
                    variant={page === 0 ? 'contained' : 'outlined'}
                    sx={{
                        color: page === 0 ? 'white' : 'gray',
                        borderRadius: 9999,
                        borderWidth: 0,
                        padding: 0,
                        minWidth: '30px',
                        maxWidth: '30px',
                        minHeight: '30px',
                        maxHeight: '30px',
                        mx: 0.5,
                    }}
                    onClick={(e) => handlePageButtonClick(e, 0)}
                >
                    1
                </Button>
            );
            if (startPage > 1) {
                buttons.push(
                    <Typography key="start-ellipsis" sx={{ mx: 0.5 }}>
                        ...
                    </Typography>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <Button
                    key={i}
                    variant={page === i ? 'contained' : 'outlined'}
                    sx={{
                        color: page === i ? 'white' : 'gray',
                        borderRadius: 9999,
                        borderWidth: 0,
                        padding: 0,
                        minWidth: '30px',
                        maxWidth: '30px',
                        minHeight: '30px',
                        maxHeight: '30px',
                        mx: 0.5,
                    }}
                    onClick={(e) => handlePageButtonClick(e, i)}
                >
                    {i + 1}
                </Button>
            );
        }

        if (endPage < totalPages - 1) {
            if (endPage < totalPages - 2) {
                buttons.push(
                    <Typography key="end-ellipsis" sx={{ mx: 0.5 }}>
                        ...
                    </Typography>
                );
            }
            buttons.push(
                <Button
                    key={totalPages - 1}
                    variant={page === totalPages - 1 ? 'contained' : 'outlined'}
                    sx={{
                        color: page === totalPages - 1 ? 'white' : 'gray',
                        borderRadius: 9999,
                        borderWidth: 0,
                        padding: 0,
                        minWidth: '30px',
                        maxWidth: '30px',
                        minHeight: '30px',
                        maxHeight: '30px',
                        mx: 0.5,
                    }}
                    onClick={(e) => handlePageButtonClick(e, totalPages - 1)}
                >
                    {totalPages}
                </Button>
            );
        }

        return buttons;
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            {renderPageButtons()}
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= totalPages - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= totalPages - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable({
    count,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
}) {
    return (
        <TablePagination
            rowsPerPageOptions={[25, 50]}
            colSpan={3}
            count={Number(count ?? 0)}
            rowsPerPage={Number(rowsPerPage ?? 0)}
            page={Number(page ?? 0)}
            slotProps={{
                select: {
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                },
            }}
            onPageChange={(_, newPage) => handleChangePage(Number(newPage ?? 0))}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(Number(event.target.value ?? 0))}
            ActionsComponent={TablePaginationActions}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
        />
    );
}