import axios from 'axios';

const errorInterceptor = (error) => {
  if (error.response) {
    console.error('Server Error:', error.response.data);
  } else if (error.request) {
    console.error('Request Error:', error.request);
  } else {
    console.error('Error:', error.message);
  }
  return Promise.reject(error);
};

const http = axios.create({
  baseURL: process.env.REACT_APP_WMS_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

if (process.env.NODE_ENV !== 'production') {
  http.interceptors.request.use(
    (config) => config,
    errorInterceptor
  );
  http.interceptors.response.use(
    (response) => response,
    errorInterceptor
  );
}

export default http;