/* eslint-disable eqeqeq */

import _ from "lodash";
import moment from "moment";

/**
 * Determine the mobile operating system
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Recebe um array e um callback para ser chamado de forma async
 * @param {*} array
 * @param {*} callback
 */
export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/**
 * Recebe um objeto com arrays ou subobjetos, percorre todas as keys, entrando
 * nos subobjectos e arrays até encotrar ou nao o valor procurado, se encontrar
 * retorno o subobjeto pertencente se nao encontrar retorna null;
 * @param {Object | Array} theObject
 * @param {String} key
 * @param {any} value
 * @returns Object
 */
export function getKeyAndValueInObject(theObject, key = "", value = "") {
  var result = null;
  if (theObject instanceof Array) {
    for (var i = 0; i < theObject.length; i++) {
      result = getKeyAndValueInObject(theObject[i], key, value);
    }
  } else {
    for (var prop in theObject) {
      if (prop == key) {
        if (theObject[prop] == value) {
          return theObject;
        }
      }
      if (theObject[prop] instanceof Object || theObject[prop] instanceof Array)
        result = getKeyAndValueInObject(theObject[prop], key, value);
    }
  }
  return result;
}

export function getErrors(error) {
  if (error?.code === "ECONNABORTED") {
    return "Tempo esgotado, tente novamente.";
  } else if (error?.message == "Network Error") {
    return "Falha na conexão: verifique sua conexão com a internet!";
  } else if (error?.response) {
    return error?.response?.data?.error || error?.response?.data?.message;
  } else if (error?.request) {
    return error?.request;
  } else if (error?.message) {
    return error?.message;
  } else {
    return error ? error : "Ocorreu um erro não identificado.";
  }
}

export function emptyToNull(str) {
  return str == "" || str == " " || str == undefined || str == null
    ? (str = "")
    : str;
}

export function getScreenSize() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getRequestOperator(op, type = 1) {
  return type === 1
    ? op === "eq"
      ? "="
      : op === "lt"
      ? ">"
      : op === "lte"
      ? ">="
      : op === "gt"
      ? "<"
      : op === "gte"
      ? "<="
      : op === "in"
      ? "[]"
      : ""
    : op === "="
    ? "eq"
    : op === ">"
    ? "lt"
    : op === ">="
    ? "lte"
    : op === "<"
    ? "gt"
    : op === "<="
    ? "gte"
    : op === "[]"
    ? "in"
    : "";
}

export function simpleAddress(stock) {
  const value = toCamelCase(stock);  
  return value?.stockLocations?.flatMap((location) =>
    location.positions?.map((position) => ({
      id: location.id,
      stock_id: position.stockId,
      address_id: position.id,
      name: location.name,
      physical_quantity_amount: location.physicalQuantityAmount ?? 0,
      block: position.block,
      street: position.street,
      tower: position.tower,
      floor: position.floor,
      se_box: position.seBox,
      se_pallet: position.sePallet,
      path: position.path,
      apartment: position.apartment,
      physical_quantity: position.physicalQuantity ?? 0,
      last_inventory: position.inventory,
    })) || []
  ) || [];
}

export const toCamelCase = (obj) => {
  if (!obj || typeof obj !== "object") return obj;
  if (Array.isArray(obj)) return obj.map((item) => toCamelCase(item));

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    acc[camelKey] = toCamelCase(obj[key]); 
    return acc;
  }, {});
};



export function renameKey(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

export function zerosEsquerda(string, length) {
  string = string.toString();
  while (string.length < length) {
    string = "0" + string;
  }
  return string;
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function sortNotMapped(data) {
  return {
    ...data,
    items: _.sortBy(data.items, ["positions_count"], ["asc"]),
  };
}

export function sortConcluedItems(data) {
  return {
    ...data,
    items: _.sortBy(data.items, (o) => o.picked_up == o.quantity),
  };
}

export function strToBool(value) {
  switch (value?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return value;
  }
}

export function parseBool(str) {
  return /true/i.test(str);
}

export function getColorMarkeplace(data, opacity = 1) {
  try {
    if (data?.marketplace?.platform_name === "B2W") {
      return `rgba(43, 111, 97, ${opacity})`;
    } else if (data?.marketplace?.platform_name === "Mercado Livre") {
      return `rgba(255, 231, 59, ${opacity})`;
    } else if (data?.marketplace.platform_name === "Magazine Luiza") {
      return `rgba(0, 157, 250, ${opacity})`;
    } else if (data?.marketplace?.platform_name === "Shopee") {
      return `rgba(231, 102, 59, ${opacity})`;
    } else if (data?.marketplace?.platform_name === "VIA") {
      return `rgba(132, 133, 136, ${opacity})`;
    } else if (data?.marketplace?.platform_name === "Renner") {
      return `rgba(242, 72, 46, ${opacity})`;
    } else {
      return "#ffffff";
    }
  } catch (error) {
    return "#ffffff";
  }
}

export function getColorInventoryDays(data = null, result) {
  const dias = parseInt(data ? moment().diff(moment(data), "d") : 60);
  return result == "A"
    ? String(
        dias <= 15
          ? "#42B7BC"
          : dias > 15 && dias <= 30
          ? "#F78B36"
          : dias > 30 && dias <= 45
          ? "#ffe73b"
          : "#111927"
      )
    : String("#111927");
}
